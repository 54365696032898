import React, { ReactNode } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

function SampleNextArrow(props: any) {
  const { onClick } = props;
  return (
    <div
      onClick={onClick}
      style={{
        right: '-110px',
        display: 'flex',
        position: 'absolute',
        top: '50%',
        background: 'white',
        borderRadius: '50%',
        padding: '12px',
        cursor: 'pointer',
      }}
    >
      <ChevronRightIcon sx={{ color: '#818893' }} />
    </div>
  );
}

function SamplePrevArrow(props: any) {
  const { onClick } = props;

  return (
    <div
      onClick={onClick}
      style={{
        left: '-110px',
        display: 'flex',
        position: 'absolute',
        top: '50%',
        background: 'white',
        borderRadius: '50%',
        padding: '12px',
        cursor: 'pointer',
      }}
    >
      <ChevronLeftIcon sx={{ color: '#818893' }} />
    </div>
  );
}

interface SliderProps {
  children: ReactNode;
  slidesToShow: number;
}

const CustomSlider = ({ children, slidesToShow }: SliderProps) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    rows: 2,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  return <Slider {...settings}>{children}</Slider>;
};

export default CustomSlider;
