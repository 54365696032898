import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Typography,
  FormControlLabel,
  Button,
  Checkbox,
  Link,
} from '@mui/material';
import { Auth } from 'aws-amplify';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
import Logo from '../components/svg/Logo';
import logoBg from '../images/logo-bg.png';

import loginUser from '../hooks/useLogin';

const theme = createTheme({
  palette: {
    primary: {
      main: '#2875EA',
      contrastText: 'white',
      dark: '#194485',
    },
    secondary: {
      main: '#F1F1F1',
      contrastText: '#262A2F',
      dark: '#F1F1F1',
    },
  },
  typography: {
    fontFamily: 'CircularStd-Regular',
  },
});

const Container = styled(Box)({
  color: '#262A2F',
  fontSize: '15px',
  padding: '50px',
  minHeight: '100vh',
  minWidth: '100vw',
  boxSizing: 'border-box',
  position: 'relative',
  backgroundPosition: 'right',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${logoBg})`,
  backgroundColor: 'white',
  '@media (max-width:375px)': {
    padding: '40px 25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundPosition: 'right 110%',
    backgroundSize: '80%',
  },
});

const LoginBox = styled(Box)({
  gap: '40px',
  display: 'flex',
  flexDirection: 'column',
  width: '345px',
  position: 'absolute',
  bottom: '10vh',
  left: '10vw',
  '@media (max-width:375px)': {
    position: 'initial',
    marginTop: '70px',
    gap: '20px',
  },
});

const MainHeader = styled(Typography)({
  fontSize: '45px',
  lineHeight: '45px',
  fontFamily: 'CircularStd-Medium',
  '@media (max-width:375px)': {
    fontSize: '25px',
  },
});

const CustomTextField = styled(TextField)({
  color: '#7A807A',
  background: '#FFF',
  borderRadius: '15px',
  '& .MuiOutlinedInput-root': {
    borderRadius: '15px',
    fontSize: '15px',
    lineHeight: '15px',
  },
  '& .MuiInputLabel-root': {
    fontSize: '15px',
    color: '#7A807A',
    lineHeight: '33px',
  },
  input: {
    padding: '20px',
    height: '25px',
  },
  fieldset: {
    borderRadius: '15px',
    border: '1px solid #CDD1CD',
  },
});

export const CustomButton = styled(Button)({
  fontFamily: 'CircularStd-Medium',
  fontSize: '15px',
  lineHeight: '20px',
  borderRadius: '50px',
  padding: '12px 25px',
  textTransform: 'none',
  boxShadow: 'none',
  minHeight: '55px',
});

const ButtonsBox = styled(Box)({
  gap: '15px',
  display: 'flex',
  flexDirection: 'column',
  marginTop: '40px',
  marginBottom: '25px',
});

const LinksBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  '@media (max-width:375px)': {
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '10px',
  },
});

const CustomLink = styled(Link)({
  fontSize: '12px',
  color: '#8F979B',
  textDecorationColor: '#8F979B',
});

const CustomCheckboxLabel = styled(FormControlLabel)({
  fontSize: '12px',
  color: '#8F979B',
});

const CustomCheckbox = styled(Checkbox)({
  color: '#CDD1CD',
});

export default function Login() {
  const [username, setUserName] = useState('');
  const [password, setPassword] = useState('');

  const navigate = useNavigate();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const user = await loginUser({ username, password });

      if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
        // This is a simplified example; in a real-world scenario, you might show a modal
        // or navigate to another component to get the new password from the user
        const newPassword: string | null = prompt('Enter new password:');
        if (newPassword !== null) {
          // Here you'll need to use Amplify's completeNewPassword method
          const authenticatedUser = await Auth.completeNewPassword(
            user,
            newPassword,
          );
          const token = authenticatedUser.signInUserSession.idToken.jwtToken;
          console.log(token);
        }
      } else {
        const token = user.signInUserSession.idToken.jwtToken;
        console.log(token);
      }
      navigate('/modules');
    } catch (error) {
      console.error('Login error:', error);
      // Handle the error in the UI (e.g., display an error message)
    }
  };

  const signInWithGoogle = async () => {
    try {
      await Auth.federatedSignIn({
        provider: CognitoHostedUIIdentityProvider.Google,
      });
    } catch (error) {
      console.error('Error signing in with Google', error);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container>
        <Logo />
        <LoginBox>
          <MainHeader>Sign in</MainHeader>
          <Box component="form" onSubmit={handleSubmit} noValidate>
            <CustomTextField
              value={username}
              onChange={(e) => setUserName(e.target.value)}
              margin="normal"
              required
              fullWidth
              id="username"
              label="Email Address"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <CustomTextField
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <CustomCheckboxLabel
              control={<CustomCheckbox value="remember" color="primary" />}
              label="Remember me"
            />
            <ButtonsBox>
              <CustomButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Submit
              </CustomButton>
              <CustomButton
                onClick={signInWithGoogle}
                fullWidth
                variant="contained"
                color="secondary"
              >
                Log in with Google
              </CustomButton>
            </ButtonsBox>
            <LinksBox>
              <CustomLink href="#">Forgot password?</CustomLink>
              <CustomLink href="#">
                {"Don't have an account? Sign Up"}
              </CustomLink>
            </LinksBox>
          </Box>
        </LoginBox>
      </Container>
    </ThemeProvider>
  );
}
