import React from 'react';
import { Typography, Fade, Modal, LinearProgress } from '@mui/material';
import {
  CustomButton,
  ModalBox,
} from '../StyledComponents/SampleStyledComponents';

interface Props {
  onClose: () => void;
  open: boolean;
  updateModule: () => void;
  isLoading: boolean;
}

function FinishedSceneSetupModal({
  open,
  onClose,
  updateModule,
  isLoading,
}: Props) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <ModalBox>
          {isLoading && <LinearProgress />}
          <Typography sx={{ fontSize: '20px', textAlign: 'center' }}>
            You have finished the Scene Setup, please click
            `&quot;Proceed`&quot; to continue.
          </Typography>
          <CustomButton
            variant={'contained'}
            onClick={() => {
              updateModule();
            }}
          >
            Proceed
          </CustomButton>
        </ModalBox>
      </Fade>
    </Modal>
  );
}

export default FinishedSceneSetupModal;
