import React from 'react';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CustomButton } from '../StyledComponents/SampleStyledComponents';
import DownloadIcon from '@mui/icons-material/Download';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useQuery } from '@tanstack/react-query';
import { getModuleCSV } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

const FullWidthContainer = styled(Box)({
  width: '100%',
  position: 'fixed',
  bottom: '0',
  left: '0',
  borderTop: '3px solid rgba(0, 0, 0, 0.05)',
  background: '#F4F4F4',
});

const FooterBox = styled(Box)({
  maxWidth: '1120px',
  width: '100%',
  height: '114px',
  margin: '0 auto',
  boxSizing: 'border-box',
  padding: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

interface Props {
  keyMoments: number[] | undefined;
  setTab: () => void;
  isModuleScriptCreated: boolean;
}
function ScriptFooter({ keyMoments, setTab, isModuleScriptCreated }: Props) {
  const { moduleId } = useParams();
  const { isLoading: isGetModuleCSVDataLoading, refetch } = useQuery({
    queryKey: ['moduleCSVData', moduleId],
    queryFn: () => getModuleCSV(moduleId ? moduleId : ''),
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const downloadCsv = (data: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `csv.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownloadCsv = () => {
    refetch().then((response) => {
      downloadCsv(response.data?.data);
    });
  };

  return (
    <FullWidthContainer>
      <FooterBox>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          {new Set(keyMoments).size >= 3 ? (
            <CheckCircleIcon sx={{ color: '#20BF6F' }} />
          ) : (
            <RadioButtonUncheckedIcon sx={{ color: '#818893' }} />
          )}
          <Typography sx={{ color: '#818893' }}>
            {new Set(keyMoments).size} of 3 Key learning moments selected
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <CustomButton
            onClick={handleDownloadCsv}
            disabled={
              new Set(keyMoments).size < 3 ||
              isGetModuleCSVDataLoading ||
              !isModuleScriptCreated
            }
            variant={'outlined'}
            endIcon={<DownloadIcon />}
          >
            Download content csv
          </CustomButton>
          <CustomButton
            onClick={setTab}
            disabled={new Set(keyMoments).size < 3}
            variant={'contained'}
            endIcon={<ChevronRightIcon />}
          >
            Done editing script
          </CustomButton>
        </Box>
      </FooterBox>
    </FullWidthContainer>
  );
}

export default ScriptFooter;
