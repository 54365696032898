import React, { useState } from 'react';
import {
  Container,
  CustomTextField,
  SampleContainer,
  SettingBox,
  SettingCounter,
  SettingHeader,
  SettingLabelBox,
  SettingsContainer,
} from '../StyledComponents/SampleStyledComponents';
import Sample from '../../images/details/survey-sample.png';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SurveyQuestions } from '../../types';
import { updateSurveyQuestions } from '../../api/apiClient';

interface Props {
  surveyQuestions: string[];
}

function SurveyQuestionScreen({ surveyQuestions }: Props) {
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const [userInput, setUserInput] = useState(surveyQuestions[0]);
  const {
    mutate: updateSurveyQuestionsMutation,
    isPending: isUpdateSurveyQuestionsLoading,
  } = useMutation({
    mutationFn: (surveyQuestions: SurveyQuestions) =>
      updateSurveyQuestions(surveyQuestions),
  });
  return (
    <Container>
      <SampleContainer>
        <img src={Sample} alt="Character info" />
      </SampleContainer>
      <SettingsContainer>
        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Question</SettingHeader>
            <SettingCounter>{userInput.length}/60</SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            id="outlined-multiline-static"
            fullWidth
            inputProps={{ maxLength: 60 }}
            multiline
            rows={4}
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder={'How confident are you in'}
            onBlur={(e) => {
              if (surveyQuestions[0] !== e.target.value && moduleId) {
                updateSurveyQuestionsMutation(
                  {
                    moduleId: moduleId,
                    surveyQuestions: [e.target.value],
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ['moduleDataById', moduleId],
                      });
                    },
                    onError: (error) => alert(error),
                  },
                );
              }
            }}
          />
        </SettingBox>
      </SettingsContainer>
    </Container>
  );
}

export default SurveyQuestionScreen;
