import React from 'react';
import { Box, Typography } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
interface SliderProps {
  name: string;
  image: string;
  handleClick: () => void;
  id: string;
  imageWidth: string;
  isSelected: boolean;
}

const SliderItem = ({
  name,
  image,
  handleClick,
  id,
  imageWidth,
  isSelected,
}: SliderProps) => {
  return (
    <Box
      key={id}
      onClick={handleClick}
      sx={{
        width: imageWidth,
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        padding: '20px 10px 20px 0',
        cursor: 'pointer',
      }}
    >
      <img
        style={{
          borderRadius: '10px',
          width: imageWidth,
          height: '184px',
          objectFit: 'cover',
        }}
        src={image}
        alt={name}
      />
      <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
        <Typography sx={{ fontSize: '20px' }}>{name}</Typography>
        {isSelected && (
          <CheckCircleIcon sx={{ color: '#20BF6F' }} fontSize={'small'} />
        )}
      </Box>
    </Box>
  );
};

export default SliderItem;
