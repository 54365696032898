import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function HomeSvg() {
  return (
    <SvgIcon style={{ width: '13px', height: '14px' }} viewBox="0 0 13 14">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="13"
        height="14"
        viewBox="0 0 13 14"
        fill="none"
      >
        <path
          d="M1.56836 12.4771H4.38086V7.78955H8.50586V12.4771H11.3184V5.16455L6.44336 1.5083L1.56836 5.16455V12.4771ZM0.443359 13.6021V4.60205L6.44336 0.102051L12.4434 4.60205V13.6021H7.38086V8.91455H5.50586V13.6021H0.443359Z"
          fill="#A8ACB1"
        />
      </svg>
    </SvgIcon>
  );
}
