import React, { ReactNode, useState } from 'react';
import { Box } from '@mui/material';
import Layout from '../components/Details/Layout';
import CustomSlider from '../components/Character/CustomSlider';
import { updateCharacter, updateEnvironment } from '../api/apiClient';

import alisha from '../images/characters/alisha.png';
import emma from '../images/characters/emma.png';
import evelyn from '../images/characters/evelyn.png';
import jimmy from '../images/characters/jimmy.png';
import parker from '../images/characters/parker.png';
import sally from '../images/characters/sally.png';
import sana from '../images/characters/sana.png';
import will from '../images/characters/will.png';

import envOne from '../images/backgrounds/01598-2940926950.png';
import envTwo from '../images/backgrounds/01599-994946132.png';
import envThree from '../images/backgrounds/01601-2980329448.png';
import envFour from '../images/backgrounds/01602-1266289809.png';
import envFive from '../images/backgrounds/01603-3362907080.png';
import envSix from '../images/backgrounds/01604-2883354325.png';
import envSeven from '../images/backgrounds/01605-3283836370.png';
import envEight from '../images/backgrounds/01606-2102328403.png';
import envNine from '../images/backgrounds/01607-3707689381.png';
import envTen from '../images/backgrounds/01608-835471515.png';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import { CustomButton } from './StyledComponents/SampleStyledComponents';
import { styled } from '@mui/material/styles';
import SliderItem from './Character/SliderItem';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Character as CharacterType,
  Environment as EnvironmentType,
  FullModule,
} from '../types';
import { useParams } from 'react-router-dom';

const CharacterContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
});

const pics = {
  '05c90ba7-33e4-49ac-bc6e-3840973a31fc': {
    image: alisha,
    name: 'Alisha',
    id: '05c90ba7-33e4-49ac-bc6e-3840973a31fc',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  'be374328-34cd-48ec-9f6c-9b5393024af3': {
    image: emma,
    name: 'Emma',
    id: 'be374328-34cd-48ec-9f6c-9b5393024af3',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '1303716e-6ba2-4bf1-a61f-1e5fd3a06cbd': {
    image: evelyn,
    name: 'Evelyn',
    id: '1303716e-6ba2-4bf1-a61f-1e5fd3a06cbd',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '8fe0e460-99a3-445b-a244-0518cf1cc2d2': {
    image: jimmy,
    name: 'Jimmy',
    id: '8fe0e460-99a3-445b-a244-0518cf1cc2d2',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  '888255b8-7311-4047-99c3-b4d9fa306712': {
    image: parker,
    name: 'Parker',
    id: '888255b8-7311-4047-99c3-b4d9fa306712',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  '5ebb954e-c5ea-40f3-be6e-a7a23923c13b': {
    image: sally,
    name: 'Sally',
    id: '5ebb954e-c5ea-40f3-be6e-a7a23923c13b',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '4095cd7c-40ab-4134-9c76-80f07c845b31': {
    image: sana,
    name: 'Sana',
    id: '4095cd7c-40ab-4134-9c76-80f07c845b31',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '4612d242-cbb6-4993-8b18-ec62e1706bcf': {
    image: will,
    name: 'Will',
    id: '4612d242-cbb6-4993-8b18-ec62e1706bcf',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
  '4095cd7c-40ab-4134-9c76-80f07c845b311': {
    image: sana,
    name: 'Sana2',
    id: '4095cd7c-40ab-4134-9c76-80f07c845b311',
    voiceId: '21m00Tcm4TlvDq8ikWAM',
  },
  '4612d242-cbb6-4993-8b18-ec62e1706bcf2': {
    image: will,
    name: 'Will2',
    id: '4612d242-cbb6-4993-8b18-ec62e1706bcf2',
    voiceId: 'tm3TDAeMaFEyTQ30JtY3',
  },
};

const envs = {
  '01598-2940926950': {
    image: envOne,
    name: 'Environment 1',
    id: '01598-2940926950',
  },
  '01599-994946132': {
    image: envTwo,
    name: 'Environment 2',
    id: '01599-994946132',
  },
  '01601-2980329448': {
    image: envThree,
    name: 'Environment 3',
    id: '01601-2980329448',
  },
  '01602-1266289809': {
    image: envFour,
    name: 'Environment 4',
    id: '01602-1266289809',
  },
  '01603-3362907080': {
    image: envFive,
    name: 'Environment 5',
    id: '01603-3362907080',
  },
  '01604-2883354325': {
    image: envSix,
    name: 'Environment 6',
    id: '01604-2883354325',
  },
  '01605-3283836370': {
    image: envSeven,
    name: 'Environment 7',
    id: '01605-3283836370',
  },
  '01606-2102328403': {
    image: envEight,
    name: 'Environment 8',
    id: '01606-2102328403',
  },
  '01607-3707689381': {
    image: envNine,
    name: 'Environment 9',
    id: '01607-3707689381',
  },
  '01608-835471515': {
    image: envTen,
    name: 'Environment 10',
    id: '01608-835471515',
  },
};

interface Props {
  setTab: (tab: number) => void;
  module: FullModule;
}

const Character = ({ setTab, module }: Props) => {
  const [activeStep, setActiveStep] = useState(0);
  const { moduleId } = useParams();
  const queryClient = useQueryClient();

  const {
    mutate: updateCharacterMutation,
    isPending: isUpdateCharacterLoading,
  } = useMutation({
    mutationFn: (character: CharacterType) => updateCharacter(character),
  });

  const {
    mutate: updateEnvironmentMutation,
    isPending: isUpdateEnvironmentLoading,
  } = useMutation({
    mutationFn: (environment: EnvironmentType) =>
      updateEnvironment(environment),
  });

  const handleSelectCharacter = (char: string) => {
    updateCharacterMutation(
      {
        character: char,
        characterName: pics[char as keyof typeof pics]['name'],
        moduleName: module.moduleName,
        moduleId: module.moduleId,
        voiceId: pics[char as keyof typeof pics]['voiceId'],
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          console.log('onSuccess updateCharacterMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };

  const handleSelectEnvironment = (env: string) => {
    updateEnvironmentMutation(
      {
        environment: env,
        moduleName: module.moduleName,
        moduleId: module.moduleId,
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          console.log('onSuccess updateEnvironmentMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };
  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const steps = [
    {
      header: 'Select a character',
      step: (
        <CustomSlider slidesToShow={4.5}>
          {Object.keys(pics).map((char) => (
            <div key={pics[char as keyof typeof pics]['id']}>
              <SliderItem
                name={pics[char as keyof typeof pics]['name']}
                image={pics[char as keyof typeof pics]['image']}
                imageWidth={'220px'}
                handleClick={() => handleSelectCharacter(char)}
                id={pics[char as keyof typeof pics]['id']}
                isSelected={
                  module.character === pics[char as keyof typeof pics]['id']
                }
              />
            </div>
          ))}
        </CustomSlider>
      ),
    },
    {
      header: 'Select a location for the experience',
      step: (
        <CustomSlider slidesToShow={3.5}>
          {Object.keys(envs).map((env, index) => (
            <div key={index}>
              <SliderItem
                name={envs[env as keyof typeof envs]['name']}
                image={envs[env as keyof typeof envs]['image']}
                imageWidth={'290px'}
                handleClick={() => handleSelectEnvironment(env)}
                id={envs[env as keyof typeof envs]['id']}
                isSelected={
                  module.environment === envs[env as keyof typeof envs]['id']
                }
              />
            </div>
          ))}
        </CustomSlider>
      ),
    },
  ];

  interface LayoutProps {
    step: ReactNode;
    header: string;
  }

  const wrapLayout = ({ step, header }: LayoutProps) => (
    <Layout
      header={header}
      isLoading={isUpdateCharacterLoading || isUpdateEnvironmentLoading}
    >
      {step}
    </Layout>
  );

  return (
    <CharacterContainer>
      {wrapLayout(steps[activeStep])}
      {activeStep !== steps.length - 1 ? (
        <CustomButton
          disabled={module.character === undefined}
          variant="contained"
          onClick={handleNext}
          endIcon={<ArrowDownwardIcon />}
        >
          Next
        </CustomButton>
      ) : (
        <CustomButton
          onClick={() => setTab(2)}
          variant="contained"
          disabled={module.environment === undefined}
        >
          Proceed
        </CustomButton>
      )}
    </CharacterContainer>
  );
};

export default Character;
