import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function Logo() {
  return (
    <SvgIcon style={{ height: '14px', width: '136px' }} viewBox="0 0 136 14">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="136"
        height="14"
        viewBox="0 0 136 14"
        fill="none"
      >
        <g clipPath="url(#clip0_2978_1913)">
          <path
            d="M13.4305 13.3196L13.413 0.680557H11.5373L6.75012 9.05842L1.87571 0.680557H0V13.3196H2.17474V5.05005L6.20657 11.8751H7.22353L11.2563 4.94171L11.2738 13.3196H13.4305ZM23.5294 13.5001C27.3343 13.5001 30.1397 10.7557 30.1397 7.00007C30.1397 3.24447 27.3343 0.5 23.5294 0.5C19.7246 0.5 16.9192 3.26253 16.9192 7.00007C16.9192 10.7376 19.7252 13.5001 23.53 13.5001H23.5294ZM23.5294 11.4418C21.0572 11.4418 19.2161 9.58204 19.2161 7.00007C19.2161 4.4181 21.0572 2.55835 23.5294 2.55835C26.0017 2.55835 27.8433 4.41768 27.8433 7.00007C27.8433 9.58246 26.0023 11.4418 23.53 11.4418H23.5294ZM36.2771 13.3196H38.5569V2.66669H42.6247V0.680557H32.2094V2.66669H36.2772L36.2771 13.3196ZM54.372 0.680557V5.89867H48.0245V0.680557H45.7451V13.3196H48.0245V7.90286H54.372V13.3196H56.6514V0.680557H54.372ZM66.0009 6.74113H63.8356V4.53665H62.5267V6.74113H60.3615V8.00083H62.5267V10.2053H63.8356V8.00083H66.0009V6.74113ZM78.5063 2.64863V0.680557H69.5469V13.3196H71.8263V8.48064H77.7525V6.49451H71.8263V2.64863H78.5063ZM82.1183 13.3196H90.815V11.3334H84.3977V0.680557H82.1183V13.3196ZM103.825 13.3196H106.209L100.669 0.680557H98.4247L92.9016 13.3196H95.2511L96.4608 10.3943H102.598L103.825 13.3196ZM97.2327 8.55286L99.5298 3.0278L101.844 8.55286H97.2327ZM122.604 13.3196L122.586 0.680557H120.711L115.923 9.05842L111.049 0.680557H109.173V13.3196H111.348V5.05005L115.38 11.8751H116.396L120.429 4.94171L120.447 13.3196H122.604ZM129.074 11.3515V7.86633H135V5.93478H129.074V2.64863H135.754V0.680557H126.794V13.3196H136V11.3515H129.074Z"
            fill="#515B6A"
          />
        </g>
        <defs>
          <clipPath id="clip0_2978_1913">
            <rect
              width="136"
              height="13"
              fill="white"
              transform="translate(0 0.5)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  );
}
