import React from 'react';
import { Typography, IconButton, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CreateIcon from '@mui/icons-material/Create';

const Container = styled(Box)({
  borderRadius: '25px',
  background: 'rgba(0, 0, 0, 0.05)',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '34px',
  boxSizing: 'border-box',
});

interface PropTypes {
  stepName: string;
  handleChangeStep: () => void;
}
function FinishedStep({ stepName, handleChangeStep }: PropTypes) {
  return (
    <Container>
      <CheckCircleIcon sx={{ color: '#20BF6F' }} fontSize={'small'} />
      <Typography>{stepName}</Typography>
      <IconButton onClick={handleChangeStep} aria-label="edit" size="small">
        <CreateIcon sx={{ color: '#C8C8C8' }} fontSize={'small'} />
      </IconButton>
    </Container>
  );
}

export default FinishedStep;
