import React from 'react';
import { Modal, Typography, CircularProgress } from '@mui/material';
import { ModalBox } from '../StyledComponents/SampleStyledComponents';

function ScriptLoadingModal({ scriptLoading }: { scriptLoading: boolean }) {
  return (
    <Modal
      open={scriptLoading}
      // onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <ModalBox>
        <CircularProgress />
        <Typography id="modal-modal-title" sx={{ fontSize: '25px' }}>
          Script generation in progress...
        </Typography>
        <Typography id="modal-modal-description" sx={{ color: '#818893' }}>
          This process typically takes around five minutes.
        </Typography>
      </ModalBox>
    </Modal>
  );
}

export default ScriptLoadingModal;
