import React, { useContext, useEffect, useState } from 'react';
import { Box, LinearProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomButton } from '../StyledComponents/SampleStyledComponents';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import {
  generateModuleScript,
  getGeneratedModuleScript,
} from '../../api/apiClient';
import { useParams } from 'react-router-dom';
import { useMutation, useQueryClient, useQuery } from '@tanstack/react-query';
import ScriptLoadingModal from './ScriptLoadingModal';
import {
  ModuleEditorContext,
  setFields,
} from '../../reducers/ModuleEditorReducer';

const Container = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  justifyContent: 'center',
});

interface Props {
  isModuleScriptCreated: boolean;
  communicationStyleA: string;
  communicationStyleB: string;
  communicationStyleC: string;
  handleShowEmptyScript: () => void;
}

function GenerateScriptButtons({
  isModuleScriptCreated,
  communicationStyleA,
  communicationStyleB,
  communicationStyleC,
  handleShowEmptyScript,
}: Props) {
  const [
    isGetGeneratedModuleScriptEnabled,
    setIsGetGeneratedModuleScriptEnabled,
  ] = useState(isModuleScriptCreated);
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      'GenerateScriptButton must be used within a ModuleEditorContext.Provider',
    );
  }
  const { dispatch } = context;

  const {
    mutate: generateModuleScriptMutation,
    isPending: isGenerateModuleScriptLoading,
  } = useMutation({
    mutationFn: (moduleId: string) =>
      generateModuleScript({
        moduleId,
        communicationStyleA,
        communicationStyleB,
        communicationStyleC,
      }),
  });

  const {
    data: generatedModuleScriptResponse,
    isLoading: isGetGeneratedModuleScriptLoading,
  } = useQuery({
    queryKey: ['generatedModuleScript', moduleId],
    queryFn: () => getGeneratedModuleScript(moduleId ? moduleId : ''),
    refetchInterval: (query) => {
      return query.state.data?.status === 202 ? 5000 : false;
    },
    enabled: isGetGeneratedModuleScriptEnabled,
  });

  useEffect(() => {
    if (generatedModuleScriptResponse?.data) {
      dispatch(
        setFields({ moduleScript: generatedModuleScriptResponse?.data }),
      );
      queryClient.invalidateQueries({ queryKey: ['moduleDataById', moduleId] });
    }
  }, [generatedModuleScriptResponse]);

  const handleGenerateScript = async () => {
    generateModuleScriptMutation(moduleId ? moduleId : '', {
      onSuccess: () => {
        setIsGetGeneratedModuleScriptEnabled(true);
        queryClient.invalidateQueries({
          queryKey: ['generatedModuleScript', moduleId],
        });
      },
      onError: (err) => console.log(err),
    });
  };
  const showEmptyScript = () => {
    handleShowEmptyScript();
  };

  if (isGenerateModuleScriptLoading || isGetGeneratedModuleScriptLoading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      <CustomButton
        variant="contained"
        onClick={handleGenerateScript}
        startIcon={<AutoAwesomeIcon />}
      >
        AI generate script
      </CustomButton>
      <CustomButton variant="contained" onClick={showEmptyScript}>
        Write script yourself
      </CustomButton>
      <ScriptLoadingModal
        scriptLoading={generatedModuleScriptResponse?.status === 202}
      />
    </Container>
  );
}

export default GenerateScriptButtons;
