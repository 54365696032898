import React, { useReducer, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Container, Button, CircularProgress } from '@mui/material';
// import levenshtein from "js-levenshtein";

// import Webcam from './Webcam';
import moduleEditorReducer, {
  initialState,
} from '../reducers/ModuleEditorReducer';
import { Moment } from '../types';
import Webcam from './Viewer/Webcam';

const DialogButton = styled(Button)({
  width: '100%',
  backgroundColor: 'transparent', // Add this line
  color: 'white',
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Change the color and opacity as needed
  },
});

const StyledVideo = styled('video')({
  width: '100vw',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
});

const WebcamContainer = styled(Container)({
  position: 'absolute',
  top: '5%',
  right: '5%',
  width: '20%',
  height: 'auto',
  zIndex: 2,
});

function ExperienceViewer() {
  const [momentNumber, setMomentNumber] = React.useState(0);
  const [listeningLoopSrc, setListeningLoopSrc] = React.useState(null);
  const [transcript, setTranscript] = React.useState('');
  const [isListening, setIsListening] = React.useState(false);
  const [isMainVideoVisible, setIsMainVideoVisible] = React.useState(true);
  // const [currentVideo, setCurrentVideo] = React.useState('http://localhost:8000/get_video?module_name=TestModule1&video=0.mp4');
  const [currentVideo, setCurrentVideo] = React.useState(
    'http://localhost:8000/modules/TestModule1/0.mp4',
  );
  const [isLoopVideoVisible, setIsLoopVideoVisible] = React.useState(false);
  const [moduleScript, setModuleScript] = React.useState(null);
  const [pathA, setPathA] = React.useState<Moment[] | null>(null);
  const [pathB, setPathB] = React.useState<Moment[] | null>(null);
  const [pathC, setPathC] = React.useState<Moment[] | null>(null);
  const [videosLoading, setVideosLoading] = React.useState(false);

  const [state, dispatch] = useReducer(moduleEditorReducer, initialState);

  useEffect(() => {
    const fetchModuleScript = async () => {
      try {
        // const response = await fetch('http://localhost:8000/get_module_script?module_name=TestModule1');

        // if (!response.ok) {
        //   throw new Error("Failed to fetch module script");
        // }

        // const moduleScript = await response.json();
        const moduleScript = await fetch(
          'http://localhost:8000/modules/TestModule1/module_script.json',
        ).then((res) => res.json());

        setModuleScript(moduleScript);
        setPathA(moduleScript.pathA);
        setPathB(moduleScript.pathB);
        setPathC(moduleScript.pathC);
      } catch (error) {
        console.error(error);
        // Handle the error appropriately here, perhaps setting some error state to show a user-friendly message.
      }
    };

    fetchModuleScript();
  }, []);

  const proceed = (branch: string) => {
    // setCurrentVideo(`http://localhost:8000/get_video?module_name=TestModule1&video=${momentNumber + 1}_${branch}.mp4`);
    setCurrentVideo(
      `http://localhost:8000/modules/TestModule1/${
        momentNumber + 1
      }_${branch}.mp4`,
    );
    setMomentNumber(momentNumber + 1);
  };

  console.log(currentVideo);

  if (videosLoading) {
    return (
      <CircularProgress
        size={150}
        style={{
          position: 'absolute',
          bottom: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      />
    );
  }

  console.log(isListening);

  return (
    <div>
      <StyledVideo controls autoPlay preload="auto" key={currentVideo}>
        <source src={currentVideo} type="video/mp4" />
      </StyledVideo>
      <Container
        style={{
          zIndex: 2,
          position: 'absolute',
          bottom: '20%',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          alignItems: 'center',
          width: '100%',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <DialogButton variant="contained" onClick={() => proceed('A')}>
          {pathA && pathA[momentNumber].learnerText}
        </DialogButton>
        <DialogButton variant="contained" onClick={() => proceed('B')}>
          {pathB && pathB[momentNumber].learnerText}
        </DialogButton>
        <DialogButton variant="contained" onClick={() => proceed('C')}>
          {pathC && pathC[momentNumber].learnerText}
        </DialogButton>
      </Container>
      <WebcamContainer
        style={{
          position: 'absolute',
          display: 'block',
          width: '20%',
          height: 'auto',
          top: '5%',
          right: '5%',
        }}
      >
        <Webcam />
      </WebcamContainer>
    </div>
  );
}

export default ExperienceViewer;
