import React from 'react';
import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { CustomButton } from '../StyledComponents/SampleStyledComponents';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const FullWidthContainer = styled(Box)({
  width: '100%',
  position: 'fixed',
  bottom: '0',
  left: '0',
  borderTop: '3px solid rgba(0, 0, 0, 0.05)',
  background: '#F4F4F4',
});

const FooterBox = styled(Box)({
  maxWidth: '1120px',
  width: '100%',
  height: '114px',
  margin: '0 auto',
  boxSizing: 'border-box',
  padding: '30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

function FeedbackFooter({ setTab }: { setTab: () => void }) {
  return (
    <FullWidthContainer>
      <FooterBox>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            width: '100%',
          }}
        >
          <CustomButton
            onClick={setTab}
            variant={'contained'}
            endIcon={<ChevronRightIcon />}
          >
            Next section
          </CustomButton>
        </Box>
      </FooterBox>
    </FullWidthContainer>
  );
}

export default FeedbackFooter;
