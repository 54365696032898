import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';

import { FullModule, ModuleScriptType } from '../types';
import FinishedStep from './Details/FinishedStep';
import Layout from './Details/Layout';
import MomentFeedback from './Feedback/MomentFeedback';
import FeedbackFooter from './Feedback/FeedbackFooter';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateModuleScript } from '../api/apiClient';
import {
  ModuleEditorContext,
  setFields,
} from '../reducers/ModuleEditorReducer';
import { useParams } from 'react-router-dom';

const FeedbackContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
});

interface Props {
  keyMoments: number[] | undefined;
  setTab: () => void;
  module: FullModule;
}

function Feedback({ keyMoments, setTab, module }: Props) {
  const [activeStep, setActiveStep] = useState(0);
  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      'Script must be used within a ModuleEditorContext.Provider',
    );
  }
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const { dispatch, state } = context;
  const { moduleScript } = state;
  const {
    mutate: updateModuleFeedbackMutation,
    isPending: isUpdateModuleFeedbackLoading,
  } = useMutation({
    mutationFn: (moduleScript: ModuleScriptType) =>
      updateModuleScript(moduleScript),
  });

  let justMounted: boolean;
  useEffect(() => {
    justMounted = true;
    if (module.moduleScript) {
      dispatch(
        setFields({
          moduleScript: module.moduleScript,
          keyMoments: new Set(module.keyMoments),
        }),
      );
    }
  }, []);
  useEffect(() => {
    if (!justMounted) {
      updateModuleFeedbackMutation(
        {
          moduleName: module.moduleName,
          moduleId: module.moduleId,
          moduleScript: moduleScript,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['moduleDataById', moduleId],
            });
            console.log('onSuccess updateModuleFeedbackMutation');
          },
          onError: (error) => alert(error),
        },
      );
    }
  }, [moduleScript]);

  const handleNext = () => {
    if (activeStep <= new Set(keyMoments).size - 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };
  const handleChangeStep = (step: number) => {
    setActiveStep(step);
  };

  const wrapLayout = (momentNumber: number) => (
    <Layout
      header={`Learner feedback for moment ${momentNumber + 1}`}
      isLoading={isUpdateModuleFeedbackLoading}
    >
      <MomentFeedback
        activeStep={activeStep}
        momentNumber={momentNumber}
        handleNextMoment={() => handleNext()}
      />
    </Layout>
  );

  // Now do the conditional return
  if (new Set(keyMoments).size < 3) {
    return <Typography>Not enough key moments to display feedback</Typography>;
  }

  return (
    <FeedbackContainer>
      {activeStep > 0
        ? Array.from(new Set(keyMoments)).map((momentNumber, index) => {
            if (index < activeStep) {
              return (
                <FinishedStep
                  key={index}
                  stepName={`Key moment feedback for moment  ${
                    momentNumber + 1
                  }`}
                  handleChangeStep={() => handleChangeStep(index)}
                />
              );
            }
          })
        : null}
      {activeStep !== new Set(keyMoments).size &&
        wrapLayout(Array.from(new Set(keyMoments))[activeStep])}
      {activeStep === new Set(keyMoments).size && (
        <FeedbackFooter setTab={setTab} />
      )}
    </FeedbackContainer>
  );
}

export default Feedback;
