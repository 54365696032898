import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function SendIconSvg() {
  return (
    <SvgIcon style={{ width: '30px', height: '29px' }} viewBox="0 0 30 29">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="29"
        viewBox="0 0 30 29"
        fill="none"
      >
        <path
          d="M29.0956 14.2528H13.5392"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.0962 14.2525L10.0044 23.4449L13.5399 14.2525L10.0044 5.0601L29.0962 14.2525Z"
          stroke="white"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
