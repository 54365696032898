import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { Box } from '@mui/material';
import BreadcrumbsNavigation from '../components/BreadcrumbsNavigation';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0065FF',
      contrastText: '#FFFFFF',
      dark: '#194485',
    },
    secondary: {
      main: '#21272F',
      contrastText: '#FFFFFF',
    },
  },
  typography: {
    fontFamily: 'CircularStd-Regular',
    fontSize: 15,
  },
});

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    checkAuth();
  }, []);

  if (!isAuthenticated) {
    navigate('/login');
  }

  function handleLogout() {
    setIsAuthenticated(false);
  }

  async function checkAuth() {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ maxWidth: '1120px', width: '100%', margin: '0 auto' }}>
        <BreadcrumbsNavigation onLogout={handleLogout} />
        <Outlet />
      </Box>
    </ThemeProvider>
  );
};

export default ProtectedRoute;
