import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
  name: string;
  mark: string;
  description: string;
  backgroundColor: string;
}

const ResponseChoice = ({
  name,
  mark,
  description,
  backgroundColor,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
        fontFamily: 'Courier',
        padding: '30px',
        borderRadius: '15px',
        background: backgroundColor,
        width: '100%',
      }}
    >
      <Box sx={{ display: 'flex', gap: '5px', justifyContent: 'center' }}>
        <Typography sx={{ textTransform: 'uppercase', fontWeight: '700' }}>
          {name}
        </Typography>
        <Typography sx={{ color: 'rgba(29, 29, 29, 0.50)' }}>{mark}</Typography>
      </Box>
      <Box sx={{ lineHeight: '22px' }}>{description}</Box>
    </Box>
  );
};

export default ResponseChoice;
