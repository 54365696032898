import React from 'react';
import { useState } from 'react';
import Sample from '../../images/details/skills-for-success.png';
import {
  Container,
  SampleContainer,
  SettingsContainer,
  SettingBox,
  SettingLabelBox,
  SettingHeader,
  SettingCounter,
  CustomTextField,
} from '../StyledComponents/SampleStyledComponents';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LearningObjective } from '../../types';
import { updateLearningObjective } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

function LearningObjectiveScreen({
  learningObjective,
}: {
  learningObjective: string;
}) {
  const { moduleId } = useParams();
  const queryClient = useQueryClient();

  const [learningObjectiveDescription, setLearningObjectiveDescription] =
    useState(learningObjective);
  const [sceneDescription, setSceneDescription] = useState('');

  const {
    mutate: updateLearningObjectiveMutation,
    isPending: isUpdateLearningObjectiveLoading,
  } = useMutation({
    mutationFn: (learningObjective: LearningObjective) =>
      updateLearningObjective(learningObjective),
  });

  return (
    <Container>
      <SampleContainer>
        <img src={Sample} alt="Skills for success" />
      </SampleContainer>
      <SettingsContainer>
        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Scene description</SettingHeader>
            <SettingCounter>{sceneDescription.length}/25</SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            inputProps={{ maxLength: 25 }}
            multiline
            rows={2}
            placeholder={'Scene description'}
            value={sceneDescription}
            onChange={(e) => setSceneDescription(e.target.value)}
          />
        </SettingBox>

        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Learning objective</SettingHeader>
            <SettingCounter>
              {learningObjectiveDescription
                ? learningObjectiveDescription.length
                : 0}
              /60
            </SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            id="outlined-multiline-static"
            fullWidth
            inputProps={{ maxLength: 60 }}
            multiline
            rows={4}
            value={learningObjectiveDescription}
            onChange={(e) => setLearningObjectiveDescription(e.target.value)}
            onBlur={(e) => {
              if (learningObjective !== e.target.value && moduleId) {
                updateLearningObjectiveMutation(
                  {
                    moduleId: moduleId,
                    learningObjective: e.target.value,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ['moduleDataById', moduleId],
                      });
                    },
                    onError: (error) => alert(error),
                  },
                );
              }
            }}
            placeholder={'Learning objective'}
            disabled={isUpdateLearningObjectiveLoading}
          />
        </SettingBox>
      </SettingsContainer>
    </Container>
  );
}

export default LearningObjectiveScreen;
