import React from 'react';
import { Typography, Fade, Modal, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {
  CustomButton,
  ModalBox,
} from '../StyledComponents/SampleStyledComponents';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
  isBuildModuleLoading: boolean;
}

function BuildModuleModal({
  open,
  onClose,
  onSubmit,
  isBuildModuleLoading,
}: Props) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <ModalBox sx={{ width: '305px', alignItems: 'flex-start' }}>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 12,
              top: 12,
              color: '#030303',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            sx={{
              fontSize: '25px',
              letterSpacing: '-0.75px',
              fontFamily: 'CircularStd-Medium',
            }}
          >
            Using your 1 free credit
          </Typography>
          <Typography
            sx={{ letterSpacing: '-0.45px', fontFamily: 'CircularStd-Light' }}
          >
            By submittng you are using up your free credit. If you wish to
            create additional experiences please contact
            <Button
              sx={{
                textTransform: 'lowercase',
                fontSize: '18px',
                lineHeight: '18px',
                padding: '0',
              }}
              variant="text"
              href="mailto:sales@mothandflamevr.com"
            >
              sales@mothandflamevr.com
            </Button>
          </Typography>
          <CustomButton
            disabled={isBuildModuleLoading}
            variant={'contained'}
            onClick={() => {
              onSubmit();
            }}
          >
            Submit
          </CustomButton>
        </ModalBox>
      </Fade>
    </Modal>
  );
}

export default BuildModuleModal;
