import React from 'react';
import { FullModule } from '../../types';
import { Box } from '@mui/material';
import ModuleDisplayCard from '../ModuleDisplayCard';

type ModuleScrollProps = {
  modules: FullModule[] | undefined;
};
function ModuleScroll(props: ModuleScrollProps) {
  const { modules } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
      {modules?.map((module: FullModule, index: number) => (
        <Box key={index}>
          <ModuleDisplayCard module={module} />
        </Box>
      ))}
    </Box>
  );
}
export default ModuleScroll;
