import { Amplify } from 'aws-amplify';

const isDevelopment = process.env.REACT_APP_ENV === 'development';

// Amplify.configure({
//   Auth: {
//     region: 'us-east-2', // your AWS region
//     userPoolId: 'us-east-2_DrYbaocQQ', // Your Cognito User Pool ID
//     userPoolWebClientId: '4qhto07568k6dgbua7pi8vfn0k', // Your Cognito App Client ID
//     mandatorySignIn: true,
//     oauth: {
//       domain: 'mf-dev-lab.auth.us-east-2.amazoncognito.com',
//       scope: ['openid'],
//       // redirectSignIn: 'https://dev-lab.mothandflamevr.com/modules',
//       redirectSignIn: isDevelopment
//         ? 'http://localhost:3000/modules'
//         : 'https://dev-lab.mothandflamevr.com/modules',
//       redirectSignOut: isDevelopment
//         ? 'http://localhost:3000'
//         : 'https://dev-lab.mothandflamevr.com/modules',
//       responseType: 'code', // or 'token', the grant type you have configured in Cognito
//     },
//   },
// });

Amplify.configure({
  Auth: {
    region: 'us-east-2', // your AWS region
    userPoolId: 'us-east-2_DrYbaocQQ', // Your Cognito User Pool ID
    userPoolWebClientId: '20ratvm08g6tdk84qfnuaf8ved', // Your Cognito App Client ID
    mandatorySignIn: true,
    oauth: {
      domain: 'mf-dev-lab.auth.us-east-2.amazoncognito.com',
      scope: ['openid'],
      // redirectSignIn: 'https://dev-lab.mothandflamevr.com/modules',
      redirectSignIn: isDevelopment
        ? 'http://localhost:3000/modules'
        : 'https://create-dev.lab.mothandflamevr.com/modules',
      redirectSignOut: isDevelopment
        ? 'http://localhost:3000/login'
        : 'https://create-dev.lab.mothandflamevr.com/login',
      responseType: 'code', // or 'token', the grant type you have configured in Cognito
    },
  },
});
