import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Box, Breadcrumbs } from '@mui/material';
import { Link, useParams, useLocation } from 'react-router-dom';

import { logoutUser } from '../hooks/useLogin';
import HomeSvg from './svg/HomeSvg';
import ChevronRightSvg from './svg/ChevronRightSvg';
import { CustomTextButton } from './StyledComponents/SampleStyledComponents';

const BreadcrumbText = styled(Typography)({
  fontSize: '12px',
  color: '#21272F',
});

function BreadcrumbsNavigation({ onLogout }: { onLogout: () => void }) {
  const { moduleId } = useParams();
  const location = useLocation();

  const breadcrumbs = [
    <Link to="/modules" key="home-link">
      <HomeSvg />
    </Link>,
    <BreadcrumbText key="2">
      {location.pathname === '/modules' ? 'Modules' : 'Create New Module'}
    </BreadcrumbText>,
    moduleId && <BreadcrumbText key={'3'}>{moduleId}</BreadcrumbText>,
  ];

  const handleLogout = () => {
    logoutUser();
    onLogout();
  };

  return (
    <Box
      sx={{
        paddingTop: '30px',
        paddingBottom: '30px',
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        position: 'sticky',
        top: '0',
        background: '#F4F4F4',
        zIndex: '2',
      }}
    >
      <Breadcrumbs separator={<ChevronRightSvg />} aria-label="breadcrumb">
        {breadcrumbs}
      </Breadcrumbs>
      <CustomTextButton variant="text" onClick={() => handleLogout()}>
        Logout
      </CustomTextButton>
    </Box>
  );
}

export default BreadcrumbsNavigation;
