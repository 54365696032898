import React from 'react';
import { useState } from 'react';
import { Typography, Card, CardContent, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';
import Sample from '../../images/details/sample1.png';
import {
  Container,
  SampleContainer,
  SettingsContainer,
  SettingBox,
  SettingLabelBox,
  SettingHeader,
  SettingCounter,
  CustomTextField,
} from '../StyledComponents/SampleStyledComponents';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Module, Summary } from '../../types';
import { updateModule, updateSummary } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

const SampleCard = styled(Card)({
  maxWidth: 325,
  borderRadius: '20px',
  height: 'fit-content',
});

const SampleCardContent = styled(CardContent)({
  background: '#000',
  color: '#fff',
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  padding: '20px',
});

const SampleHeader = styled(Typography)({
  letterSpacing: '-0.7px',
  fontFamily: 'CircularStd-Medium',
});

const SampleDescription = styled(Typography)({
  fontSize: '12px',
  color: '#A1A6AD',
});

interface Props {
  moduleName: string;
  moduleSummary: string;
}

function ModuleDescription({ moduleName, moduleSummary }: Props) {
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const [moduleDescription, setModuleDescription] = useState(moduleSummary);
  const [moduleTitle, setModuleTitle] = useState(moduleName);

  const {
    mutate: updateModuleNameMutation,
    isPending: isUpdateModuleNameLoading,
  } = useMutation({
    mutationFn: (moduleName: Module) => updateModule(moduleName),
  });

  const { mutate: updateSummaryMutation, isPending: isUpdateSummaryLoading } =
    useMutation({
      mutationFn: (summary: Summary) => updateSummary(summary),
    });

  return (
    <Container>
      {/*todo uncomment if we will need to return Sample image*/}
      {/*<SampleContainer>*/}
      {/*  <SampleCard>*/}
      {/*    <CardMedia component="img" alt="sample" height="195" image={Sample} />*/}
      {/*    <SampleCardContent>*/}
      {/*      <SampleHeader>This is a longer module name that goes</SampleHeader>*/}
      {/*      <SampleDescription>*/}
      {/*        This is a short description of this module that goes on for*/}
      {/*        several lines. This is a short description of this module that*/}
      {/*        goes on for several lines.*/}
      {/*      </SampleDescription>*/}
      {/*    </SampleCardContent>*/}
      {/*  </SampleCard>*/}
      {/*</SampleContainer>*/}
      <SettingsContainer>
        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Module title</SettingHeader>
            <SettingCounter>{moduleTitle.length}/25</SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            inputProps={{ maxLength: 25 }}
            multiline
            rows={2}
            value={moduleTitle}
            onChange={(e) => setModuleTitle(e.target.value)}
            placeholder={'Module title'}
            onBlur={(e) => {
              if (moduleName !== e.target.value && moduleId) {
                updateModuleNameMutation(
                  {
                    moduleName: e.target.value,
                    moduleId: moduleId,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ['moduleDataById', moduleId],
                      });
                    },
                    onError: (error) => alert(error),
                  },
                );
              }
            }}
            disabled={isUpdateModuleNameLoading}
          />
        </SettingBox>

        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Module description</SettingHeader>
            <SettingCounter>{moduleDescription.length}/60</SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            placeholder={'Module description'}
            id="outlined-multiline-static"
            inputProps={{ maxLength: 60 }}
            multiline
            rows={6}
            value={moduleDescription}
            onChange={(e) => setModuleDescription(e.target.value)}
            onBlur={(e) => {
              if (moduleSummary !== e.target.value && moduleId) {
                updateSummaryMutation(
                  {
                    moduleName: moduleName,
                    moduleId: moduleId,
                    summary: e.target.value,
                  },
                  {
                    onSuccess: () => {
                      queryClient.invalidateQueries({
                        queryKey: ['moduleDataById', moduleId],
                      });
                    },
                    onError: (error) => alert(error),
                  },
                );
              }
            }}
            disabled={isUpdateSummaryLoading}
          />
        </SettingBox>
      </SettingsContainer>
    </Container>
  );
}

export default ModuleDescription;
