import React, { useState } from 'react';
import {
  Typography,
  FormControl,
  Select,
  MenuItem,
  Box,
  InputBase,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Layout from '../Details/Layout';

const CommunicationOptionsBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
});

const CommunicationSelectBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '15px',
  width: '100%',
});

const CommunicationSelectLabel = styled(Typography)({
  fontSize: '15px',
  color: '#818893',
});

const CommunicationSelect = styled(InputBase)(() => ({
  '& .MuiInputBase-input': {
    borderRadius: '10px',
    fontSize: 20,
    border: '1px solid #F4F4F4',
    padding: '20px 40px',
    '&:hover': {
      border: '1px solid #0065FF',
    },
  },
}));

const communicationSelectPseudoElements = (
  color: string,
  afterContent: string,
  background: string,
) => {
  return {
    '& .MuiInputBase-input': {
      background: background,
    },
    '&::after': {
      content: `"${afterContent}"`,
      color: color,
      right: '40px',
      position: 'absolute',
    },
    '&::before': {
      content: "''",
      height: '12px',
      width: '12px',
      left: '20px',
      position: 'absolute',
      borderRadius: '50%',
      background: color,
    },
  };
};

type CommunicationStylesProps = {
  isModuleScriptCreated: boolean;
  isBorderVisible: boolean;
  communicationStyleA: string;
  communicationStyleB: string;
  communicationStyleC: string;
  handleSelectCommunicationStyle: (
    val: string,
    communicationStyleType: string,
  ) => void;
  isLoading: boolean;
};

function CommunicationStyles({
  communicationStyleA,
  communicationStyleB,
  communicationStyleC,
  isModuleScriptCreated,
  isBorderVisible,
  handleSelectCommunicationStyle,
  isLoading,
}: CommunicationStylesProps) {
  const [communicationStyleAOptions] = useState([
    'Direct',
    'Empathetic',
    'Supportive',
    'Constructive',
    'Inclusive',
    'Effective',
    'Concerned',
    'Motivational',
  ]);
  const [communicationStyleBOptions] = useState([
    'Diagnostic',
    'Self-Focused',
    'Defensive',
    'Leading',
    'Vague',
    'Passive',
    'Dismissive',
    'Overemotional',
    'Rude',
  ]);
  const [communicationStyleCOptions] = useState([
    'Directive',
    'Ineffective',
    'Critical',
    'Hostile',
    'Authoritative',
    'Avoidant',
    'Polarized',
    'Microaggressive',
  ]);

  return (
    <Layout
      header={'Communication Styles'}
      description={
        isModuleScriptCreated
          ? ''
          : 'Before we can get to the script we need to define the communication options' +
            ' that will be available to the learner during the experience.'
      }
      inversionBackground={isModuleScriptCreated}
      onScrollFixed={true}
      isBorderVisible={isBorderVisible}
      isLoading={isLoading}
    >
      <Box>
        <CommunicationOptionsBox>
          <CommunicationSelectBox>
            <CommunicationSelectLabel>Ideal choice </CommunicationSelectLabel>
            <FormControl fullWidth>
              <Select
                labelId="ideal-choice-label"
                data-testid="ideal-choice-select"
                id="ideal-choice"
                value={communicationStyleA}
                IconComponent={ExpandMoreIcon}
                input={
                  <CommunicationSelect
                    sx={communicationSelectPseudoElements(
                      '#20BF6F',
                      '+1',
                      isModuleScriptCreated ? '#FFFFFF' : '#F4F4F4',
                    )}
                  />
                }
                label="ideal-choice-label"
                onChange={(e) =>
                  handleSelectCommunicationStyle(
                    e.target.value,
                    'communicationStyleA',
                  )
                }
              >
                {communicationStyleAOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CommunicationSelectBox>
          <CommunicationSelectBox>
            <CommunicationSelectLabel>
              Suboptimal choice
            </CommunicationSelectLabel>
            <FormControl fullWidth>
              <Select
                labelId="suboptimal-choice-label"
                data-testid="suboptimal-choice-select"
                id="suboptimal-choice"
                value={communicationStyleB}
                IconComponent={ExpandMoreIcon}
                input={
                  <CommunicationSelect
                    sx={communicationSelectPseudoElements(
                      '#FCC072',
                      '-1',
                      isModuleScriptCreated ? '#FFFFFF' : '#F4F4F4',
                    )}
                  />
                }
                label="suboptimal-choice-label"
                onChange={(e) =>
                  handleSelectCommunicationStyle(
                    e.target.value,
                    'communicationStyleB',
                  )
                }
              >
                {communicationStyleBOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CommunicationSelectBox>
          <CommunicationSelectBox>
            <CommunicationSelectLabel>Bad choice</CommunicationSelectLabel>
            <FormControl fullWidth>
              <Select
                labelId="bad-choice-label"
                data-testid="bad-choice-select"
                id="bad-choice"
                value={communicationStyleC}
                IconComponent={ExpandMoreIcon}
                input={
                  <CommunicationSelect
                    sx={communicationSelectPseudoElements(
                      '#E21D1D',
                      '-2',
                      isModuleScriptCreated ? '#FFFFFF' : '#F4F4F4',
                    )}
                  />
                }
                label="bad-choice-label"
                onChange={(e) =>
                  handleSelectCommunicationStyle(
                    e.target.value,
                    'communicationStyleC',
                  )
                }
              >
                {communicationStyleCOptions.map((option, index) => (
                  <MenuItem key={index} value={option}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </CommunicationSelectBox>
        </CommunicationOptionsBox>
      </Box>
    </Layout>
  );
}

export default CommunicationStyles;
