import React, { useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import {
  Container,
  CustomButton,
} from '../StyledComponents/SampleStyledComponents';
import BuildModuleModal from './BuildModuleModal';
import ModuleInProgressModal from './ModuleInProgressModal';
import { useMutation, useQuery } from '@tanstack/react-query';
import { getModuleCSV, setModuleAssets } from '../../api/apiClient';
import { useParams } from 'react-router-dom';

function FinishModuleScreen() {
  const { moduleId } = useParams();
  const [modalVisible, setModalVisible] = useState(false);
  const [moduleInProgressModalVisible, setModuleInProgressModalVisible] =
    useState(false);

  const {
    mutate: setModuleAssetsMutation,
    isPending: isSetModuleAssetsLoading,
  } = useMutation({
    mutationFn: (moduleId: string) => setModuleAssets(moduleId),
  });

  const { isLoading: isGetModuleCSVDataLoading, refetch } = useQuery({
    queryKey: ['moduleCSVData', moduleId],
    queryFn: () => getModuleCSV(moduleId ? moduleId : ''),
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const downloadCsv = (data: string) => {
    const url = window.URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `csv.csv`;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
  };

  const handleDownloadCsv = () => {
    refetch().then((response) => {
      downloadCsv(response.data?.data);
    });
  };

  const handleBuildModule = () => {
    setModuleAssetsMutation(moduleId ? moduleId : '', {
      onSuccess: () => {
        setModalVisible(false);
        setModuleInProgressModalVisible(true);
      },
      onError: (err) => {
        alert(err);
      },
    });
  };

  return (
    <Container>
      <CustomButton
        variant="outlined"
        endIcon={<DownloadIcon />}
        onClick={handleDownloadCsv}
        disabled={isGetModuleCSVDataLoading}
      >
        Download content csv
      </CustomButton>
      <CustomButton variant="contained" onClick={() => setModalVisible(true)}>
        Build module
      </CustomButton>
      <ModuleInProgressModal
        open={moduleInProgressModalVisible}
        onClose={() => setModuleInProgressModalVisible(false)}
        onSubmit={() => console.log('go to module page')}
      />
      <BuildModuleModal
        open={modalVisible}
        onClose={() => setModalVisible(false)}
        onSubmit={handleBuildModule}
        isBuildModuleLoading={isSetModuleAssetsLoading}
      />
    </Container>
  );
}

export default FinishModuleScreen;
