import React, { useContext } from 'react';
import {
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Divider,
} from '@mui/material';
import { ModuleEditorContext } from '../../reducers/ModuleEditorReducer';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined';
import LinkOutlinedIcon from '@mui/icons-material/LinkOutlined';
import CellTowerOutlinedIcon from '@mui/icons-material/CellTowerOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { deleteModuleById } from '../../api/apiClient';

interface Props {
  onClose: (value: string) => void;
  selectedValue: string;
  open: boolean;
  module: any;
  anchorEl: HTMLButtonElement | null;
}

function ModuleMenuPopover(props: Props) {
  const { onClose, selectedValue, open, module, anchorEl } = props;

  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      'ModuleDisplayCard must be used within a ModuleEditorContext.Provider',
    );
  }
  const queryClient = useQueryClient();

  // useEffect(() => {
  //   console.log(queryClient.getQueryData(['moduleListData']), 'getQueryData');
  // }, []);

  const handleClose = () => {
    onClose(selectedValue);
  };

  const {
    mutate: deleteModuleByIdMutation,
    isPending: isDeleteModuleByIdLoading,
  } = useMutation({
    mutationFn: (moduleId: string) => deleteModuleById(moduleId),
  });

  const handleListItemClick = (action: string, module: any) => {
    switch (action) {
      case 'editName':
        console.log('editName');
        break;
      case 'copyLink':
        navigator.clipboard.writeText(
          `${window.location.origin}/module/${module.moduleId}`,
        );
        break;
      case 'delete':
        deleteModuleByIdMutation(module.moduleId, {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ['moduleListData'] });
          },
          onError: (err) => alert(err),
        });
        break;
      default:
        console.log('default');
        break;
    }
  };

  const options = ['Edit module name', 'Copy share link', 'Delete module'];

  // TODO
  const icons = [
    <EditOutlinedIcon sx={{ color: '#30343A' }} key={1} />,
    <LinkOutlinedIcon sx={{ color: '#30343A' }} key={3} />,
    <DeleteOutlineOutlinedIcon sx={{ color: '#30343A' }} key={5} />,
  ];

  const actions = ['editName', 'copyLink', 'delete'];

  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      sx={{
        '& .MuiPaper-root': {
          borderRadius: '20px',
          background: '#FFF',
          boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
          padding: '45px 30px',
        },
      }}
    >
      <List sx={{ padding: '0' }}>
        {options.map((option, idx) => (
          <ListItem
            disableGutters
            key={idx}
            sx={{
              padding: `${idx ? '15px' : '0'} 0 ${
                options.length - 1 !== idx ? '15px' : '0'
              } 0`,
              borderBottom: `1px solid ${
                options.length - 1 !== idx
                  ? 'rgba(0, 0, 0, 0.05)'
                  : 'transparent'
              }`,
            }}
          >
            <ListItemButton
              autoFocus
              onClick={() => handleListItemClick(actions[idx], module)}
              sx={{
                padding: '0 15px 0 0',
                gap: '10px',
                '&:hover': { backgroundColor: 'transparent' },
              }}
            >
              <ListItemAvatar>
                <Avatar
                  sx={{
                    backgroundColor: 'rgba(0, 0, 0, 0.05)',
                    height: '50px',
                    width: '50px',
                  }}
                >
                  {icons[idx]}
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                sx={{
                  '& .MuiTypography-root': {
                    fontSize: '20px',
                    fontFamily: 'CircularStd-Medium',
                    letterSpacing: '-0.6px',
                  },
                }}
                primary={option}
              />
            </ListItemButton>
            <Divider
              sx={{
                borderColor: 'rgba(0, 0, 0, 0.05)',
              }}
            />
          </ListItem>
        ))}
      </List>
    </Popover>
  );
}

export default ModuleMenuPopover;
