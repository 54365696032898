import axios, { InternalAxiosRequestConfig } from 'axios';
import { Auth } from 'aws-amplify';
axios.defaults.baseURL =
  'https://pj931adns9.execute-api.us-east-2.amazonaws.com';

axios.interceptors.request.use(
  async function (config: InternalAxiosRequestConfig) {
    const session = await Auth.currentSession();
    const token = session.getAccessToken().getJwtToken();
    config.headers.set('Authorization', `Bearer ${token}`);
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);
