import * as React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function ChevronRightSvg() {
  return (
    <SvgIcon style={{ width: '7px', height: '11px' }} viewBox="0 0 7 11">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="7"
        height="11"
        viewBox="0 0 7 11"
        fill="none"
      >
        <path
          d="M1.44336 10.0002L5.44336 5.85201L1.44336 1.70386"
          stroke="#A8ACB1"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  );
}
