import React, { ReactNode } from 'react';
import {
  LayoutContainer,
  MainHeader,
  RegularText,
  LayoutContent,
  LayoutLoader,
} from '../StyledComponents/LayoutStyledComponents';
import { CircularProgress } from '@mui/material';
interface LayoutProps {
  children: ReactNode;
  header: string;
  description?: string;
  additional?: string;
  isLoading?: boolean;
  inversionBackground?: boolean;
  onScrollFixed?: boolean;
  isBorderVisible?: boolean;
}

function Layout({
  children,
  header,
  description,
  additional,
  isLoading,
  inversionBackground,
  onScrollFixed,
  isBorderVisible,
}: LayoutProps) {
  return (
    <LayoutContainer
      sx={{
        background: inversionBackground ? '#F4f4f4' : '#FFFFFF',
        border: inversionBackground ? '3px solid rgba(0, 0, 0, 0.05)' : 'none',
        position: onScrollFixed ? 'sticky' : '',
        top: onScrollFixed ? '27px' : '',
        zIndex: onScrollFixed ? '1' : '',
        borderColor: isBorderVisible ? '#F4F4F4' : '',
        borderRadius: isBorderVisible ? '0px' : '',
        '&::before': {
          content: "''",
          height: '3px',
          width: '100vw',
          position: 'absolute',
          background: isBorderVisible ? 'rgba(0, 0, 0, 0.05)' : 'transparent',
          bottom: '-2px',
        },
      }}
    >
      <MainHeader>{header}</MainHeader>
      {description && <RegularText>{description}</RegularText>}
      {additional && (
        <RegularText sx={{ color: '#A1A6AD' }}>{additional}</RegularText>
      )}
      <LayoutContent>{children}</LayoutContent>
      {isLoading && (
        <LayoutLoader>
          <CircularProgress />
        </LayoutLoader>
      )}
    </LayoutContainer>
  );
}

export default Layout;
