import axios, { AxiosResponse } from 'axios';
import {
  Character,
  Module,
  Environment,
  KeyMoments,
  ModuleScriptType,
  Summary,
  LearningObjective,
  FullModule,
  Thumbnail,
  SurveyQuestions,
  Chat,
  CommunicationStyles,
} from '../types';

export const createModule = (moduleName: string): Promise<AxiosResponse> => {
  return axios.post('/moduleMetadata', { moduleName: moduleName });
};

export const getModuleList = async (): Promise<FullModule[]> => {
  return (await axios.get('/moduleList')).data;
};

export const getModuleById = async (moduleId: string): Promise<FullModule> => {
  return (
    await axios.get('/moduleMetadata', { params: { moduleId: moduleId } })
  ).data as FullModule;
};

export const deleteModuleById = async (
  moduleId: string,
): Promise<FullModule> => {
  return await axios.delete('/moduleMetadata', {
    params: { moduleId: moduleId },
  });
};

export const updateModule = (moduleData: Module): Promise<AxiosResponse> => {
  return axios.post('/moduleMetadata', moduleData);
};

export const updateChat = (data: Chat): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const updateCharacter = (data: Character): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const updateEnvironment = (
  data: Environment,
): Promise<AxiosResponse> => {
  return updateModule(data);
};
export const updateCommunicationStyles = (
  data: CommunicationStyles,
): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const updateKeyMoments = (data: KeyMoments): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const updateModuleScript = (
  data: ModuleScriptType,
): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const updateSummary = (data: Summary): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const updateLearningObjective = (
  data: LearningObjective,
): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const updateSurveyQuestions = (
  data: SurveyQuestions,
): Promise<AxiosResponse> => {
  return updateModule(data);
};

export const setThumbnail = (data: Thumbnail): Promise<AxiosResponse> => {
  return axios.post(
    '/moduleThumbnail',
    { image: data.image },
    { params: { format: 'png', moduleId: data.moduleId } },
  );
};

export const getThumbnail = async (moduleId: string): Promise<Thumbnail> => {
  return (
    await axios.get('/moduleThumbnail', {
      params: { moduleId: moduleId, format: 'png' },
    })
  ).data;
};

export const generateModuleScript = ({
  moduleId,
  communicationStyleA,
  communicationStyleB,
  communicationStyleC,
}: {
  moduleId: string;
  communicationStyleA: string;
  communicationStyleB: string;
  communicationStyleC: string;
}): Promise<AxiosResponse> => {
  return axios.post('/moduleScript', null, {
    params: {
      moduleId: moduleId,
      communicationStyleA: communicationStyleA,
      communicationStyleB: communicationStyleB,
      communicationStyleC: communicationStyleC,
    },
  });
};

export const getGeneratedModuleScript = (
  moduleId: string,
): Promise<AxiosResponse> => {
  return axios.get('/moduleScript', { params: { moduleId: moduleId } });
};

export const setModuleAssets = (moduleId: string): Promise<AxiosResponse> => {
  return axios.post('/moduleAssets', null, { params: { moduleId: moduleId } });
};

export const getModuleCSV = async (
  moduleId: string,
): Promise<AxiosResponse> => {
  return await axios.get('/moduleCSV', { params: { moduleId: moduleId } });
};
