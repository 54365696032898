import React from 'react';
import { Typography, Fade, Modal, Link, Box } from '@mui/material';
import {
  CustomButton,
  ModalBox,
} from '../StyledComponents/SampleStyledComponents';
import SyncIcon from '@mui/icons-material/Sync';

interface Props {
  onClose: () => void;
  onSubmit: () => void;
  open: boolean;
}

function ModuleInProgressModal({ open, onClose, onSubmit }: Props) {
  return (
    <Modal open={open} onClose={onClose} closeAfterTransition>
      <Fade in={open}>
        <ModalBox sx={{ width: '310px', alignItems: 'flex-start' }}>
          <Box
            sx={{
              borderRadius: '50%',
              backgroundColor: '#20BF6F',
              width: '40px',
              height: '40px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <SyncIcon sx={{ color: 'white' }} />
          </Box>
          <Typography
            sx={{
              fontSize: '25px',
              letterSpacing: '-0.75px',
              fontFamily: 'CircularStd-Medium',
              lineHeight: '25px',
            }}
          >
            Your module is under construction!
          </Typography>
          <Typography
            sx={{
              letterSpacing: '-0.45px',
              fontFamily: 'CircularStd-Regular',
              lineHeight: '22px',
            }}
          >
            The typical build time is about 20 minutes. When your module is
            ready it will be available for review on your “My Modules” page.
          </Typography>
          <Typography
            sx={{
              letterSpacing: '-0.45px',
              fontFamily: 'CircularStd-Regular',
              lineHeight: '22px',
            }}
          >
            Once your module is ready you can use{' '}
            <Link href="#" sx={{ textDecoration: 'none' }}>
              this link
            </Link>{' '}
            to review it.
          </Typography>
          <CustomButton
            variant={'contained'}
            onClick={() => {
              onSubmit();
            }}
          >
            Go to My Modules page
          </CustomButton>
        </ModalBox>
      </Fade>
    </Modal>
  );
}

export default ModuleInProgressModal;
