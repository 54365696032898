import React, { useState } from 'react';
import { Box, Typography, Dialog, TextField } from '@mui/material';
import { CustomButton } from '../StyledComponents/SampleStyledComponents';

interface Props {
  onClose: () => void;
  open: boolean;
  createModule: (name: string) => void;
}

function CreateModuleDialog(props: Props) {
  const [moduleName, setModuleName] = useState('');
  const { onClose, open, createModule } = props;
  const handleClose = () => {
    onClose();
    setModuleName('');
  };
  return (
    <Dialog
      onClose={handleClose}
      open={open}
      maxWidth="xl"
      sx={{
        '& .MuiDialog-paper': { borderRadius: '20px' },
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(129, 136, 147, 0.50)',
        },
      }}
    >
      <Box
        sx={{
          width: 600,
          display: 'flex',
          flexDirection: 'column',
          gap: '30px',
          padding: '45px 30px',
        }}
      >
        <Typography sx={{ fontSize: '25px', letterSpacing: '-0.75px' }}>
          Name new module
        </Typography>
        <Box
          sx={{
            display: 'flex',
            gap: '20px',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <TextField
            id="create-module-textfield"
            fullWidth
            label="Module name"
            value={moduleName}
            onChange={(e) => setModuleName(e.target.value)}
            sx={{
              borderRadius: '15px',
              background: 'rgba(0, 0, 0, 0.05)',
              fieldset: {
                border: 'none',
              },
            }}
            inputProps={{ maxLength: 40 }}
          />
          <Box
            sx={{
              color: '#818893',
              fontSize: '15px',
              minWidth: '44px',
              textAlign: 'right',
            }}
          >
            {moduleName.length}/40
          </Box>
        </Box>
        <CustomButton
          disabled={!moduleName}
          variant="contained"
          sx={{ width: '180px' }}
          onClick={() => {
            createModule(moduleName);
            onClose();
            setModuleName('');
          }}
        >
          Save
        </CustomButton>
      </Box>
    </Dialog>
  );
}

export default CreateModuleDialog;
