import React from 'react';
import {
  Typography,
  Button,
  Box,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CreateIcon from '@mui/icons-material/Create';
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import { getThumbnail, setThumbnail } from '../../api/apiClient';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Thumbnail } from '../../types';

const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '20px',
});

const SecondaryText = styled(Typography)({
  fontSize: '12px',
  fontFamily: 'CircularStd-Medium',
  color: '#818893',
});

function ModuleThumbnail() {
  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    data: moduleThumbnail,
    isPending: isGetModuleThumbnailLoading,
    error,
    isError,
  } = useQuery({
    queryKey: ['moduleThumbnail', moduleId],
    queryFn: () => getThumbnail(moduleId ? moduleId : ''),
    refetchOnWindowFocus: false,
    enabled: !!moduleId,
  });

  if (isError) {
    console.error(`${error} on GET /moduleThumbnail`);
  }

  const { mutate: setThumbnailMutation, isPending: isSetThumbnailLoading } =
    useMutation({
      mutationFn: (thumbnail: Thumbnail) => setThumbnail(thumbnail),
    });

  const getBase64 = (file: any) => {
    return new Promise((resolve) => {
      let baseURL: string | ArrayBuffer | null = '';
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    if (file) {
      getBase64(file).then((result) => {
        postImage(result);
      });
    }
  };

  const handleDeleteImage = () => {
    console.log('delete image');
  };

  const postImage = async (base64: any) => {
    console.log(base64);
    if (moduleId) {
      setThumbnailMutation(
        {
          moduleId: moduleId,
          image: base64,
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ['moduleThumbnail', moduleId],
            });
          },
          onError: (err) => alert(err),
        },
      );
    }
  };

  if (isSetThumbnailLoading || isGetModuleThumbnailLoading) {
    return <LinearProgress />;
  }

  return (
    <Container>
      {moduleThumbnail ? (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-end',
            gap: '10px',
            position: 'relative',
          }}
        >
          <img
            src={moduleThumbnail.image}
            alt="Uploaded thumbnail"
            style={{ width: '300px', height: 'auto', borderRadius: '20px' }}
          />
          <IconButton
            component="label"
            aria-label="edit"
            size="small"
            sx={{
              borderRadius: '50%',
              backgroundColor: '#2875EA',
              padding: '10px',
              right: '-50px',
              position: 'absolute',
            }}
          >
            <CreateIcon fontSize={'small'} sx={{ color: '#ffffff' }} />
            <input
              type="file"
              hidden
              onChange={handleImageChange}
              accept="image/*"
            />
          </IconButton>

          {/* Uncomment this button if you want to add delete functionality again
          <Button
            onClick={handleDeleteImage}
            sx={{ position: 'absolute', top: '10px', right: '10px' }}
            color="error"
          >
            Delete
          </Button>
          */}
        </Box>
      ) : (
        <Button
          variant="outlined"
          component="label"
          endIcon={<DriveFolderUploadIcon />}
          sx={{
            fontSize: '15px',
            borderRadius: '50px',
            padding: '12px 25px',
            textTransform: 'none',
          }}
        >
          Upload thumbnail
          <input
            type="file"
            hidden
            onChange={handleImageChange}
            accept="image/*"
          />
        </Button>
      )}
      <SecondaryText>(Full bleed images should be 350 x 220 px)</SecondaryText>
    </Container>
  );
}

export default ModuleThumbnail;
