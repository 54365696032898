import React, { useState, useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  FormControlLabel,
  Box,
  Divider,
  Popover,
  IconButton,
  Tooltip,
  Button,
} from '@mui/material';

import DragHandleIcon from '@mui/icons-material/DragHandle';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CloseIcon from '@mui/icons-material/Close';

import {
  Moment,
  KeyMoments as KeyMomentsType,
  FullModule,
} from '../../../types';
import {
  ModuleEditorContext,
  setFields,
} from '../../../reducers/ModuleEditorReducer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateKeyMoments } from '../../../api/apiClient';
import {
  MomentBox,
  MomentContainer,
  MomentHeaderBox,
  MomentHeader,
  MomentCardsContainer,
  HeaderContainer,
  MomentLabel,
  CustomTextField,
} from './styledComponents';

function LearnerText({
  value,
  setValue,
  save,
}: {
  value: string;
  setValue: (value: string) => void;
  save: (val: string) => void;
}) {
  return (
    <CustomTextField
      id="outlined-multiline-static"
      fullWidth
      inputProps={{ maxLength: 300 }}
      multiline
      rows={6}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => save(e.target.value)}
      placeholder={'Who do you think is the best candidate for the job?'}
    />
  );
}

function CharacterText({
  value,
  setValue,
  save,
}: {
  value: string;
  setValue: (value: string) => void;
  save: (val: string) => void;
}) {
  return (
    <CustomTextField
      id="outlined-multiline-static"
      fullWidth
      inputProps={{ maxLength: 300 }}
      multiline
      rows={12}
      value={value}
      onChange={(e) => setValue(e.target.value)}
      onBlur={(e) => save(e.target.value)}
      placeholder={'Who do you think is the best candidate for the job?'}
    />
  );
}

interface Props {
  pathA: Moment;
  pathB: Moment;
  pathC: Moment;
  momentNumber: number;
  module: FullModule;
}

export default function MomentCard({
  pathA,
  pathB,
  pathC,
  momentNumber,
  module,
}: Props) {
  const context = useContext(ModuleEditorContext);

  if (!context) {
    throw new Error('MomentCard must be used within a ModuleEditorProvider');
  }

  const { dispatch } = context;

  const [isChecked, setChecked] = useState(
    new Set(module.keyMoments).has(momentNumber),
  );
  const [editableTextPathALearner, setEditableTextPathALearner] = useState(
    pathA.learnerText,
  );
  const [editableTextPathBLearner, setEditableTextPathBLearner] = useState(
    pathB.learnerText,
  );
  const [editableTextPathCLearner, setEditableTextPathCLearner] = useState(
    pathC.learnerText,
  );
  const [editableTextPathACharacter, setEditableTextPathACharacter] = useState(
    pathA.characterText,
  );
  const [editableTextPathBCharacter, setEditableTextPathBCharacter] = useState(
    pathB.characterText,
  );
  const [editableTextPathCCharacter, setEditableTextPathCCharacter] = useState(
    pathC.characterText,
  );

  useEffect(() => {
    setEditableTextPathALearner(pathA.learnerText);
  }, [pathA.learnerText]);

  useEffect(() => {
    setEditableTextPathBLearner(pathB.learnerText);
  }, [pathB.learnerText]);

  useEffect(() => {
    setEditableTextPathCLearner(pathC.learnerText);
  }, [pathC.learnerText]);

  useEffect(() => {
    setEditableTextPathACharacter(pathA.characterText);
  }, [pathA.characterText]);

  useEffect(() => {
    setEditableTextPathBCharacter(pathB.characterText);
  }, [pathB.characterText]);

  useEffect(() => {
    setEditableTextPathCCharacter(pathC.characterText);
  }, [pathC.characterText]);

  const { moduleId } = useParams();
  const queryClient = useQueryClient();
  const {
    mutate: updateKeyMomentsMutation,
    isPending: isUpdateKeyMomentsLoading,
  } = useMutation({
    mutationFn: (keyMoments: KeyMomentsType) => updateKeyMoments(keyMoments),
  });

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    const newKeyMoments = new Set(module.keyMoments);

    if (event.target.checked) {
      newKeyMoments.add(momentNumber);
    } else {
      newKeyMoments.delete(momentNumber);
    }

    dispatch(
      setFields({
        keyMoments: newKeyMoments,
      }),
    );

    updateKeyMomentsMutation(
      {
        moduleName: module.moduleName,
        moduleId: module.moduleId,
        keyMoments: Array.from(newKeyMoments),
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ['moduleDataById', moduleId],
          });
          console.log('onSuccess updateKeyMomentsMutation');
        },
        onError: (error) => alert(error),
      },
    );
  };

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const OnBlurTextarea = (
    path: string,
    role: string,
    currentText: string | undefined,
    prevText: string,
  ) => {
    let newMoment;
    if (path === 'pathA') {
      newMoment =
        role === 'learner'
          ? { ...pathA, learnerText: editableTextPathALearner }
          : { ...pathA, characterText: editableTextPathACharacter };
    } else if (path === 'pathB') {
      newMoment =
        role === 'learner'
          ? { ...pathB, learnerText: editableTextPathBLearner }
          : { ...pathB, characterText: editableTextPathBCharacter };
    } else if (path === 'pathC') {
      newMoment =
        role === 'learner'
          ? { ...pathC, learnerText: editableTextPathCLearner }
          : { ...pathC, characterText: editableTextPathCCharacter };
    }

    //todo remove
    if (currentText !== prevText) {
      dispatch({
        type: 'EDIT_MOMENT',
        payload: {
          path: `${path}`,
          momentIndex: momentNumber,
          newMoment: newMoment,
        },
      });
    }
  };

  return (
    <MomentContainer>
      <Divider
        variant="middle"
        sx={{ color: '#E2E2E2', width: '100%', height: '1px', marginLeft: '0' }}
      />
      <MomentHeaderBox>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <DragHandleIcon sx={{ color: '#C8C8C8' }} />
          <MomentHeader className="character">
            Moment {momentNumber + 1}
          </MomentHeader>
          <IconButton
            aria-describedby={id}
            aria-label="moment-menu"
            size="small"
            onClick={handleClick}
          >
            <MoreHorizIcon sx={{ color: '#C8C8C8' }} />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'center',
            }}
            sx={{
              '& .MuiPaper-root': {
                borderRadius: '10px',
                background: '#FFF',
                boxShadow: '0px 4px 15px 0px rgba(0, 0, 0, 0.10)',
                padding: '20px 30px',
              },
            }}
          >
            <Button
              variant="text"
              style={{
                textTransform: 'none',
                padding: '0',
                marginRight: '20px',
              }}
            >
              Delete moment
            </Button>
            <IconButton size="small" onClick={handleClose}>
              <CloseIcon sx={{ color: '#030303' }} />
            </IconButton>
          </Popover>
        </Box>

        <Tooltip
          title="Select 3 moments which will be used to evaluate accomplishment of the learning objective: Manage emotions."
          placement="top-end"
          arrow
          componentsProps={{
            tooltip: {
              sx: {
                background: '#0065FF',
                color: '#FFF',
                padding: '20px 30px',
                fontSize: '15px',
                borderRadius: '10px',
                textAlign: 'center',
                '& .MuiTooltip-arrow': {
                  color: '#0065FF',
                },
              },
            },
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={isChecked}
                onChange={handleCheckboxChange}
                disabled={new Set(module.keyMoments).size >= 3 && !isChecked}
                sx={{
                  color: 'rgba(48, 52, 58, 0.20)',
                  '&.Mui-checked': {
                    color: '#30343A',
                  },
                }}
              />
            }
            sx={{ fontSize: '12px', color: 'rgba(48, 52, 58, 0.50)' }}
            labelPlacement="start"
            label="Key learning moment"
          />
        </Tooltip>
      </MomentHeaderBox>
      <MomentCardsContainer>
        <MomentBox>
          <HeaderContainer>
            <MomentLabel>Learner</MomentLabel>
            <MomentLabel sx={{ color: '#A1A6AD' }}>(+1)</MomentLabel>
          </HeaderContainer>
          <LearnerText
            value={editableTextPathALearner}
            setValue={setEditableTextPathALearner}
            save={(val) =>
              OnBlurTextarea('pathA', 'learner', pathA.learnerText, val)
            }
          />
        </MomentBox>
        <MomentBox>
          <HeaderContainer>
            <MomentLabel>Learner</MomentLabel>
            <MomentLabel sx={{ color: '#A1A6AD' }}>(-1)</MomentLabel>
          </HeaderContainer>
          <LearnerText
            value={editableTextPathBLearner}
            setValue={setEditableTextPathBLearner}
            save={(val) =>
              OnBlurTextarea('pathB', 'learner', pathB.learnerText, val)
            }
          />
        </MomentBox>
        <MomentBox>
          <HeaderContainer>
            <MomentLabel>Learner</MomentLabel>
            <MomentLabel sx={{ color: '#A1A6AD' }}>(-2)</MomentLabel>
          </HeaderContainer>
          <LearnerText
            value={editableTextPathCLearner}
            setValue={setEditableTextPathCLearner}
            save={(val) =>
              OnBlurTextarea('pathC', 'learner', pathC.learnerText, val)
            }
          />
        </MomentBox>
      </MomentCardsContainer>
      <MomentCardsContainer>
        <MomentBox>
          <HeaderContainer>
            <MomentLabel fontWeight="bold">
              {module.characterName ? module.characterName : 'Character name'}
            </MomentLabel>
          </HeaderContainer>
          <CharacterText
            value={editableTextPathACharacter}
            setValue={setEditableTextPathACharacter}
            save={(val) =>
              OnBlurTextarea('pathA', 'character', pathA.characterText, val)
            }
          />
        </MomentBox>
        <MomentBox>
          <HeaderContainer>
            <MomentLabel>
              {module.characterName ? module.characterName : 'Character name'}
            </MomentLabel>
          </HeaderContainer>
          <CharacterText
            value={editableTextPathBCharacter}
            setValue={setEditableTextPathBCharacter}
            save={(val) =>
              OnBlurTextarea('pathB', 'character', pathB.characterText, val)
            }
          />
        </MomentBox>
        <MomentBox>
          <HeaderContainer>
            <MomentLabel>
              {module.characterName ? module.characterName : 'Character name'}
            </MomentLabel>
          </HeaderContainer>
          <CharacterText
            value={editableTextPathCCharacter}
            setValue={setEditableTextPathCCharacter}
            save={(val) =>
              OnBlurTextarea('pathC', 'character', pathC.characterText, val)
            }
          />
        </MomentBox>
      </MomentCardsContainer>
    </MomentContainer>
  );
}
