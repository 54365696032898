import React, { useState } from 'react';
import Sample from '../../images/details/characterIntro.png';
import {
  Container,
  SampleContainer,
  SettingsContainer,
  SettingBox,
  SettingLabelBox,
  SettingHeader,
  SettingCounter,
  CustomTextField,
} from '../StyledComponents/SampleStyledComponents';

function CharacterInfoScreen() {
  const [narratorDescription, setNarratorDescription] = useState('');
  return (
    <Container>
      <SampleContainer>
        <img src={Sample} alt="Character info" />
      </SampleContainer>
      <SettingsContainer>
        <SettingBox>
          <SettingLabelBox>
            <SettingHeader>Narrator</SettingHeader>
            <SettingCounter>{narratorDescription.length}/60</SettingCounter>
          </SettingLabelBox>
          <CustomTextField
            id="outlined-multiline-static"
            fullWidth
            inputProps={{ maxLength: 60 }}
            multiline
            rows={4}
            value={narratorDescription}
            onChange={(e) => setNarratorDescription(e.target.value)}
            onBlur={() => {
              console.log('on blur Narrator');
            }}
            placeholder={'Narrator'}
          />
        </SettingBox>
      </SettingsContainer>
    </Container>
  );
}

export default CharacterInfoScreen;
