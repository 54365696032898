import { styled } from '@mui/material/styles';
import { Box, Typography } from '@mui/material';

export const LayoutContainer = styled(Box)({
  borderRadius: '25px',
  background: '#FFF',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '40px',
  width: '100%',
  boxSizing: 'border-box',
  gap: '25px',
  position: 'relative',
});

export const LayoutLoader = styled(Box)({
  position: 'absolute',
  background: 'rgba(255, 255, 255, 0.50)',
  top: '0px',
  width: '100%',
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: '25px',
});

export const MainHeader = styled(Typography)({
  color: '#30343A',
  fontSize: '30px',
});

export const RegularText = styled(Typography)({
  color: '#30343A',
  fontSize: '15px',
  width: 'min(70%, 457px)',
  textAlign: 'center',
  whiteSpace: 'pre-line',
});

export const LayoutContent = styled(Box)({
  width: '100%',
});
