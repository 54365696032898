import { styled } from '@mui/material/styles';
import { Box, TextField, Typography } from '@mui/material';

export const MomentBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '10px',
  width: '100%',
});

export const MomentContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: '30px',
  width: '100%',
});

export const MomentHeaderBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const MomentHeader = styled(Typography)({
  textTransform: 'uppercase',
  fontSize: '12px',
  color: 'rgba(48, 52, 58, 0.50)',
});

export const MomentCardsContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  gap: '35px',
});

export const HeaderContainer = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '5px',
  justifyContent: 'center',
});

export const MomentLabel = styled(Box)({
  fontFamily: 'Courier',
  textTransform: 'uppercase',
  fontWeight: '700',
});

export const CustomTextField = styled(TextField)({
  borderRadius: '15px',
  border: '1px solid #E2E2E2',
  background: '#FFF',
  textarea: {
    fontFamily: 'Courier',
    lineHeight: '20px',
    letterSpacing: '-0.15px',
  },
  fieldset: {
    border: 'none',
  },
  '& .MuiInputBase-root': {
    padding: '30px',
  },
});
