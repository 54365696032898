import React from 'react';
import { Box, Typography } from '@mui/material';

import { ChatMessageProps } from '../../types';
import UserIconSvg from '../svg/UserIcon';

export default function Message(props: ChatMessageProps) {
  return (
    <div>
      <Box
        sx={{
          my: 2,
          display: 'flex',
          flexFlow: 'row',
          justifyContent: props.isCustomer ? 'right' : 'left',
          textAlign: props.isCustomer ? 'right' : 'left',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: props.isCustomer ? 'flex-end' : 'flex-start',
          }}
        >
          {props.isCustomer ? (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: '15px',
                width: '80%',
                justifyContent: 'flex-end',
              }}
            >
              <Box
                sx={{
                  borderRadius: '10px',
                  background: '#DFEBFC',
                  padding: '10px 20px',
                }}
              >
                {props.content}
              </Box>
              <UserIconSvg />
            </Box>
          ) : (
            <Typography
              sx={{ fontSize: '20px', width: '80%', letterSpacing: '-0.2px' }}
            >
              {props.content}
            </Typography>
          )}
        </Box>
      </Box>
    </div>
  );
}
