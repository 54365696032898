import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { useQuery, useMutation } from '@tanstack/react-query';
import { styled } from '@mui/material/styles';
import AddIcon from '@mui/icons-material/Add';
import {
  Typography,
  Box,
  LinearProgress,
  Divider,
  AlertTitle,
  Alert,
} from '@mui/material';
import { CustomButton } from './StyledComponents/SampleStyledComponents';
import { ModuleEditorContext } from '../reducers/ModuleEditorReducer';
import CreateModuleDialog from '../components/ModuleList/CreateModuleDialog';
import ModuleScroll from '../components/ModuleList/ModuleScroll';
import { createModule, getModuleList } from '../api/apiClient';

const ModuleListContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '735px',
  margin: '0 auto',
});

const ModuleListHeader = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

const ModuleListText = styled(Typography)({
  fontFamily: 'CircularStd-Medium',
  fontSize: '40px',
  lineHeight: '40px',
  letterSpacing: '-0.4px',
});

const ModulesCountText = styled(Typography)({
  fontFamily: 'CircularStd-Medium',
  fontSize: '15px',
  letterSpacing: '-0.15px',
});

function ModuleList() {
  const [isCreateModuleDialogOpen, setIsCreateModuleDialogOpen] =
    useState(false);
  const navigate = useNavigate();
  const context = useContext(ModuleEditorContext);
  if (!context) {
    throw new Error(
      'Script must be used within a ModuleEditorContext.Provider',
    );
  }
  const { dispatch } = context;
  useEffect(() => {
    dispatch({ type: 'REFRESH_MESSAGES' });
    dispatch({ type: 'REFRESH_MODULE_SCRIPT' });
  }, []);

  const {
    data: moduleListData,
    isLoading: isGetModuleListLoading,
    isError,
  } = useQuery({
    queryKey: ['moduleListData'],
    queryFn: getModuleList,
  });

  const { mutate: createModuleMutation, isPending: isCreateModuleLoading } =
    useMutation({
      mutationFn: (moduleName: string) => createModule(moduleName),
    });

  const onCreateModule = (moduleName: string) => {
    createModuleMutation(moduleName, {
      onSuccess: ({ data }: AxiosResponse) => {
        navigate(`/module/${data.moduleId}`);
      },
      onError: (error) => alert(error),
    });
  };

  return (
    <ModuleListContainer>
      <ModuleListHeader>
        <ModuleListText>My modules</ModuleListText>
        <CustomButton
          variant="contained"
          onClick={() => setIsCreateModuleDialogOpen(true)}
          startIcon={<AddIcon />}
        >
          Create Module
        </CustomButton>
      </ModuleListHeader>

      <Box sx={{ marginTop: '50px' }}>
        {(isGetModuleListLoading || isCreateModuleLoading) && (
          <LinearProgress />
        )}
        {isError && (
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            Sorry, we couldn&apos;t load the module list :(
          </Alert>
        )}
        {!isGetModuleListLoading && !isError && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <ModulesCountText>{`Modules (${moduleListData?.length})`}</ModulesCountText>
            <Divider
              sx={{
                marginBottom: '10px',
                borderColor: 'rgba(0, 0, 0, 0.05)',
                borderWidth: '1.5px',
              }}
            />
            <ModuleScroll modules={moduleListData} />
          </Box>
        )}
      </Box>
      <CreateModuleDialog
        open={isCreateModuleDialogOpen}
        onClose={() => setIsCreateModuleDialogOpen(false)}
        createModule={onCreateModule}
      />
    </ModuleListContainer>
  );
}

export default ModuleList;
